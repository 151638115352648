import React from "react";
import { Layout, Row, Col } from 'antd';
import ProductRequestForm from "../components/productRequestForm";

const { Content } = Layout;

export default () => {

    return (
        <Layout>
            <Content style={Styles.container}>
                <Row style={Styles.mainRow}>
                    <Col style={Styles.mainCol} xxl={20} xl={18} lg={20} md={21} sm={20} xs={22} >
                        <ProductRequestForm />
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

const Styles = {
    container: {
        minHeight: '100vh',
        background: '#544bff'
    },
    mainRow: {
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mainCol: {
        background: '#ffffff',
        marginTop: 25,
        marginBottom: 25,
        paddingTop: 25,
        paddingLeft: 40,
        paddingRight: 40,
        border: 'solid',
        borderWidth: 1,
        borderRadius: 5,
        boxShadow: '0 0 10px #000000'
    }
}