import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import { Row, Col, Form, Input, Space, Button, Divider, Modal, Typography } from 'antd';
import { FacebookOutlined, InstagramOutlined, UserOutlined, MobileOutlined } from '@ant-design/icons';
import Styles from "./productRequestForm.module.css";
import logo from "../../images/small-logo.png";

const { Title } = Typography;
const layout = {
	labelCol: { span: 24 }
};


const ProductRequestForm = () => {
	const [form] = Form.useForm();
	const [inputState, setInputState] = useState(false);
	const [loading, setLoading] = useState(false);

	const onReset = () => {
		form.resetFields();
	};

	const success = (message) => {
		Modal.success({
			content: message,
		});
	}

	const error = (message) => {
		Modal.error({
			title: 'Error',
			content: message,
		});
	}

	const onFinish = (data) => {
		setLoading(true);
		setInputState(true);
		data['requestAt'] = moment();
		axios.post('https://api.upsvonline.com/product/request', data, {
			headers: {
				'Content-Type': 'application/json;charset=UTF-8',
			}
		})
			.then((res) => {
				console.log(res);
				if (res.status === 200) {
					onReset();
					success(res.data.message);
				}
				setLoading(false);
				setInputState(false);
			})
			.catch((err) => {
				if (err.response) {
					error(err.response.data.message);
				} else {
					error("Ha ocurrido un error al enviar la solicitud, intentalo de nuevo");
				}
				setLoading(false);
				setInputState(false);
			});
	}

	return (
		<Form form={form} {...layout} name="solicitud" onFinish={onFinish}>

			<Row>
				<Col className={Styles.titleContainer} span={24} >
					<img className={Styles.logo} src={logo} alt="Ultimate Phone" />
				</Col>
				<Col className={Styles.titleContainer} span={24} >
					<Title level={2} className={Styles.title}>Solicitud de producto</Title>
				</Col>
			</Row>

			<Row gutter={{ md: 16, lg: 16, xl: 16, xxl: 16 }}>
				<Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24} >
					<Divider orientation="left"><MobileOutlined /> Información del dispositivo</Divider>
					<Form.Item name="brand" label="Marca" rules={[{ required: true, message: 'La marca es requerida' }]}>
						<Input disabled={inputState} />
					</Form.Item>
					<Form.Item name="model" label="Modelo" rules={[{ required: true, message: 'El modelo es requerido' }]}>
						<Input disabled={inputState} />
					</Form.Item>
					<Form.Item name="color" label="Color" rules={[{ required: true, message: 'El color es requerido' }]}>
						<Input disabled={inputState} />
					</Form.Item>
					<Form.Item name="specs" label="Especificaciones" rules={[{ required: true, message: 'Las especificaciones son requeridas' }]}>
						<Input.TextArea disabled={inputState} />
					</Form.Item>
				</Col>

				<Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24} >
					<Divider orientation="left"><UserOutlined /> Información de contacto</Divider>

					<Form.Item name="name" label="Nombre" rules={[{ required: true, message: 'El nombre es requerido' }]}>
						<Input disabled={inputState} />
					</Form.Item>
					<Form.Item name="contactNumber" label="Numero de contacto" rules={[{ required: true, message: 'El numero de contacto es requerido' }]}>
						<Input disabled={inputState} />
					</Form.Item>
					<Form.Item name="email" label="Correo electrónico" rules={[{ whitespace: true, required: true, message: 'El Correo electrónico es requerido' }, { pattern: new RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$'), message: 'El Correo eléctronico no posee un formato válido' }]}>
						<Input disabled={inputState} />
					</Form.Item>
					<Space style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }} align="start">
						<Form.Item
							label={
								<span><FacebookOutlined /> Facebook</span>
							}
							name={['socialMedia', 'facebook']}
							rules={[{ whitespace: true, message: 'No se permiten solo espacios en blanco' }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							label={
								<span><InstagramOutlined /> Instagram</span>
							}
							name={['socialMedia', 'instagram']}
							rules={[{ whitespace: true, message: 'No se permiten solo espacios en blanco' }]}
						>
							<Input />
						</Form.Item>
					</Space>
				</Col>
				<Col span={24} className={Styles.btnCol} >
					<Form.Item>
						<Button className={Styles.btnSend} type="primary" loading={loading} htmlType="submit">
							Enviar solicitud
				        </Button>
					</Form.Item>
				</Col>
			</Row>

		</Form>
	)
}

export default ProductRequestForm